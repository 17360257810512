<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
      v-loading="loading"
    >
      <div slot="title" style="color: #409eff">
        <div class="business-center">
          <div class="before-change">更改前</div>
          <div class="after-the-alteration">更改后</div>
        </div>
      </div>
      <div class="business-center">
        <div class="business-left" v-if="form.oldData">
          <el-descriptions title="投标信息" direction="vertical" :column="3" border>
            <el-descriptions-item
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('bidName') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 标的名称 </template>

              <div>
                {{ form.oldData.bidName }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidType') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label"> 采购方式 </template>
              <div class="input_style">
                <div>{{ form.oldData.bidType | dict(dictData.bidType) }}</div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidOwnerUnit')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 业主单位 </template>
              <div>
                {{ form.oldData.bidOwnerUnit }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidAgency') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <template slot="label">代理招标单位 </template>
              <div>
                {{ form.oldData.bidAgency }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="是否重点关注"
              :contentStyle="
                form.changeFieldList.includes('bidHostEscort')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <el-radio-group v-model="form.oldData.bidHostEscort" :disabled="true">
                <el-radio label="IS_FLAG">是</el-radio>
                <el-radio label="NOT_FLAG">否 </el-radio>
              </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item
              label="报名截止时间"
              :contentStyle="
                form.changeFieldList.includes('bidRegistrationDeadline')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidRegistrationDeadline | timeFormat }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="开标时间"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningTime')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidOpeningTime | timeFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="开标地点"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningLocation')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidOpeningLocation }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="开标方式"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningMethod')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidOpeningMethod | dict(dictData.bidOpeningMethod) }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="信息来源"
              :contentStyle="
                form.changeFieldList.includes('bidSource') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.bidSource }}
              </div>
            </el-descriptions-item>

            <!-- <el-descriptions-item
              label="中标金额（元）"
              :contentStyle="
                form.changeFieldList.includes('bidWinningAmount')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidWinningAmount | applyAmount }}
              </div></el-descriptions-item
            > -->

            <el-descriptions-item
              :span="2"
              label="商务项"
              :contentStyle="
                form.changeFieldList.includes('bidBusinessItems')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidBusinessItems }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="报价方式"
              :contentStyle="
                form.changeFieldList.includes('bidQuotationMethod')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidQuotationMethod }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="2"
              label="备注"
              :contentStyle="
                form.changeFieldList.includes('bidRemark') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.bidRemark }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="投标截止时间"
              :contentStyle="
                form.changeFieldList.includes('bidSubmissionDeadline')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidSubmissionDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="开标介质有效期"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningMediaValidity')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidOpeningMediaValidity | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="招标代理服务费（元）"
              :contentStyle="
                form.changeFieldList.includes('biddingAgencyServiceFee')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.biddingAgencyServiceFee | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="文件交付时间"
              :contentStyle="
                form.changeFieldList.includes('bidDocumentDeliveryTime')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidDocumentDeliveryTime | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="文件交付地点"
              :contentStyle="
                form.changeFieldList.includes('bidDocumentDeliveryLocation')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidDocumentDeliveryLocation }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="报价的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidQuotationDeadline')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidQuotationDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="合作单位材料提供的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidPartnerMaterialDeadline')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidPartnerMaterialDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="公示期结束时间"
              :contentStyle="
                form.changeFieldList.includes('bidPublicAnnouncementEndTime')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidPublicAnnouncementEndTime | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="授权代表材料提供的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidAuthorizedDeadline')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidAuthorizedDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="标书技术方案书写人方案最迟的给予时间"
              :contentStyle="
                form.changeFieldList.includes('bidTechnicalProposalDeadline')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.bidTechnicalProposalDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidDescription')
                  ? { 'background-color': '#bff0de' }
                  : ''
              "
            >
              <template slot="label"> 投标描述 </template>
              <div>
                {{ form.oldData.bidDescription }}
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="商务信息" direction="vertical" :column="5" border>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 商务名称 </template>

              <div>
                {{ form.oldData.business.businessName || '' }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 业主单位 </template>
              <div class="input_style">
                <div>{{ form.oldData.business.ownerUnit || '' }}</div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 经办人 </template>
              <div>
                {{ form.oldData.business.contacts || '' }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <template slot="label">经办人电话 </template>
              <div>
                {{ form.oldData.business.contactsPhone || '' }}
              </div>
            </el-descriptions-item>

            <!-- <el-descriptions-item
              label="填报人"
              :contentStyle="
                form.changeFieldList.includes('businessId') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.business.initiatorName || '' }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="实际商务负责人"
              :contentStyle="
                form.changeFieldList.includes('businessId') ? { 'background-color': '#bff0de' } : ''
              "
            >
              <div>
                {{ form.oldData.business.principalUserName || '' }}
              </div>
            </el-descriptions-item> -->

            <el-descriptions-item
              label="申请时间"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.business.createdDate | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="商务类型"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.oldData.business.businessType | dict(dictData.businessType) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="状态"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#bff0de', height: '46px' }
                  : ''
              "
            >
              <div>
                <span v-if="form.oldData.business.status === 0">暂存</span>
                <span
                  v-if="form.oldData.business.status == 1 && form.oldData.business.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span
                  v-if="form.oldData.business.status == 1 && form.oldData.business.type == 'ZGH'"
                  >董事长审核中</span
                >
                <span
                  v-if="form.oldData.business.status == 3 && form.oldData.business.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span
                  v-if="form.oldData.business.status == 3 && form.oldData.business.type == 'ZGH'"
                  >董事长审核通过</span
                >
                <span v-if="form.oldData.business.status == 5">已移交计划经营部</span>
                <span v-if="form.oldData.business.status == 6">已创建项目</span>
                <!-- <span v-if="form.oldData.business.status == 7">项目完结</span> -->
                <!-- <span v-if="form.oldData.business.status == 8">商务失败</span> -->
                <span v-if="form.oldData.business.status == 9">商务暂停</span>
                <span v-if="form.oldData.business.status == 10">商务终结</span>
                <span
                  v-if="form.oldData.business.status == 21 && form.oldData.business.type == 'XSH'"
                  >高管审核不通过</span
                >
                <span
                  v-if="form.oldData.business.status == 21 && form.oldData.business.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <!-- 暂时不放开  24-09-14 -->
          <!-- <div style="width: 100%">
            <label
              class="el-form-item__label"
              style="font-weight: 900; color: black; font-size: 16px"
              >费用管理</label
            >
            <el-table
              :data="form.oldData.paymentApplications"
              :cell-class-name="leftCellClassName"
              border
              style="width: 100%"
            >
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <el-table
                    v-show="scope.row.paymentDetail?.length > 0"
                    :data="scope.row.paymentDetail"
                    style="margin-left: 59px; width: 92%"
                    max-height="400"
                    border
                  >
                    <el-table-column
                      prop="typeOne"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目一"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="typeTwo"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目二"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="typeThree"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目三"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="cost" min-width="110" label="金额（元）" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.cost | applyAmount }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                prop="expenseNumber"
                label="单据编号"
                align="center"
                width="175"
              ></el-table-column>
              <el-table-column
                prop="applicantName"
                sortable
                label="申请人"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="deptName"
                sortable
                width="130"
                label="费用所属部门"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="collectionUnit"
                sortable
                min-width="280"
                :show-overflow-tooltip="false"
                label="收款单位"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="sumCost" width="120" sortable label="申请金额" align="center">
                <template slot-scope="scope">
                  {{ scope.row.sumcost | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="projectBudget"
                width="120"
                sortable
                label="申请时间"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.createdDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="statusName"
                :show-overflow-tooltip="false"
                width="150"
                label="流程进度"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div> -->
        </div>

        <div class="business-right" v-if="form.newData">
          <el-descriptions title="投标信息" direction="vertical" :column="3" border>
            <el-descriptions-item
              :span="1"
              :contentStyle="
                form.changeFieldList.includes('bidName') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 标的名称 </template>

              <div>
                {{ form.newData.bidName }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidType') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label"> 采购方式 </template>
              <div class="input_style">
                <div>{{ form.newData.bidType | dict(dictData.bidType) }}</div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidOwnerUnit')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 业主单位 </template>
              <div>
                {{ form.newData.bidOwnerUnit }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidAgency') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <template slot="label">代理招标单位 </template>
              <div>
                {{ form.newData.bidAgency }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="是否重点关注"
              :contentStyle="
                form.changeFieldList.includes('bidHostEscort')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <el-radio-group v-model="form.newData.bidHostEscort" :disabled="true">
                <el-radio label="IS_FLAG">是</el-radio>
                <el-radio label="NOT_FLAG">否 </el-radio>
              </el-radio-group>
            </el-descriptions-item>

            <el-descriptions-item
              label="报名截止时间"
              :contentStyle="
                form.changeFieldList.includes('bidRegistrationDeadline')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidRegistrationDeadline | timeFormat }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="开标时间"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningTime')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidOpeningTime | timeFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="开标地点"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningLocation')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidOpeningLocation }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="开标方式"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningMethod')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidOpeningMethod | dict(dictData.bidOpeningMethod) }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="信息来源"
              :contentStyle="
                form.changeFieldList.includes('bidSource') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.bidSource }}
              </div>
            </el-descriptions-item>

            <!-- <el-descriptions-item
              label="中标金额（元）"
              :contentStyle="
                form.changeFieldList.includes('bidWinningAmount')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidWinningAmount | applyAmount }}
              </div></el-descriptions-item
            > -->

            <el-descriptions-item
              label="商务项"
              :contentStyle="
                form.changeFieldList.includes('bidBusinessItems')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidBusinessItems }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="报价方式"
              :contentStyle="
                form.changeFieldList.includes('bidQuotationMethod')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidQuotationMethod }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="2"
              label="备注"
              :contentStyle="
                form.changeFieldList.includes('bidRemark') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.bidRemark }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="投标截止时间"
              :contentStyle="
                form.changeFieldList.includes('bidSubmissionDeadline')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidSubmissionDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="开标介质有效期"
              :contentStyle="
                form.changeFieldList.includes('bidOpeningMediaValidity')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidOpeningMediaValidity | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="招标代理服务费（元）"
              :contentStyle="
                form.changeFieldList.includes('biddingAgencyServiceFee')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.biddingAgencyServiceFee | applyAmount }}
              </div></el-descriptions-item
            >
            <el-descriptions-item
              label="文件交付时间"
              :contentStyle="
                form.changeFieldList.includes('bidDocumentDeliveryTime')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidDocumentDeliveryTime | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="文件交付地点"
              :contentStyle="
                form.changeFieldList.includes('bidDocumentDeliveryLocation')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidDocumentDeliveryLocation }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="报价的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidQuotationDeadline')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidQuotationDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="合作单位材料提供的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidPartnerMaterialDeadline')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidPartnerMaterialDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="公示期结束时间"
              :contentStyle="
                form.changeFieldList.includes('bidPublicAnnouncementEndTime')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidPublicAnnouncementEndTime | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="授权代表材料提供的最迟时间"
              :contentStyle="
                form.changeFieldList.includes('bidAuthorizedDeadline')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidAuthorizedDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="标书技术方案书写人方案最迟的给予时间"
              :contentStyle="
                form.changeFieldList.includes('bidTechnicalProposalDeadline')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.bidTechnicalProposalDeadline | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('bidDescription')
                  ? { 'background-color': '#eef9b2' }
                  : ''
              "
            >
              <template slot="label"> 投标描述 </template>
              <div>
                {{ form.newData.bidDescription }}
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions title="商务信息" direction="vertical" :column="5" border>
            <el-descriptions-item
              :span="3"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 商务名称 </template>

              <div>
                {{ form.newData.business.businessName || '' }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :span="2"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 业主单位 </template>
              <div class="input_style">
                <div>{{ form.newData.business.ownerUnit || '' }}</div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <template slot="label"> 经办人 </template>
              <div>
                {{ form.newData.business.contacts || '' }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <template slot="label">经办人电话 </template>
              <div>
                {{ form.newData.business.contactsPhone || '' }}
              </div>
            </el-descriptions-item>

            <!-- <el-descriptions-item
              label="填报人"
              :contentStyle="
                form.changeFieldList.includes('businessId') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.business.initiatorName || '' }}
              </div></el-descriptions-item
            >

            <el-descriptions-item
              label="实际商务负责人"
              :contentStyle="
                form.changeFieldList.includes('businessId') ? { 'background-color': '#eef9b2' } : ''
              "
            >
              <div>
                {{ form.newData.business.principalUserName || '' }}
              </div>
            </el-descriptions-item> -->

            <el-descriptions-item
              label="申请时间"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.business.createdDate | dateFormat }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="商务类型"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                {{ form.newData.business.businessType | dict(dictData.businessType) }}
              </div>
            </el-descriptions-item>

            <el-descriptions-item
              label="状态"
              :contentStyle="
                form.changeFieldList.includes('businessId')
                  ? { 'background-color': '#eef9b2', height: '46px' }
                  : ''
              "
            >
              <div>
                <span v-if="form.newData.business.status == 0">暂存</span>
                <span
                  v-if="form.newData.business.status == 1 && form.newData.business.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span
                  v-if="form.newData.business.status == 1 && form.newData.business.type == 'ZGH'"
                  >董事长审核中</span
                >
                <span
                  v-if="form.newData.business.status == 3 && form.newData.business.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span
                  v-if="form.newData.business.status == 3 && form.newData.business.type == 'ZGH'"
                  >董事长审核通过</span
                >
                <span v-if="form.newData.business.status == 5">已移交计划经营部</span>
                <span v-if="form.newData.business.status == 6">已创建项目</span>
                <!-- <span v-if="form.newData.business.status == 7">项目完结</span> -->
                <!-- <span v-if="form.newData.business.status == 8">商务失败</span> -->
                <span v-if="form.newData.business.status == 9">商务暂停</span>
                <span v-if="form.newData.business.status == 10">商务终结</span>
                <span
                  v-if="form.newData.business.status == 21 && form.newData.business.type == 'XSH'"
                  >高管审核不通过</span
                >
                <span
                  v-if="form.newData.business.status == 21 && form.newData.business.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>

          <!-- <div style="width: 100%">
            <label
              class="el-form-item__label"
              style="font-weight: 900; color: black; font-size: 16px"
              >费用管理</label
            >
            <el-table
              :data="form.newData.paymentApplications"
              :cell-class-name="rightCellClassName"
              border
              style="width: 100%"
            >
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <el-table
                    v-show="scope.row.paymentDetail?.length > 0"
                    :data="scope.row.paymentDetail"
                    style="margin-left: 59px; width: 92%"
                    max-height="400"
                    border
                  >
                    <el-table-column
                      prop="typeOne"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目一"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="typeTwo"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目二"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="typeThree"
                      :show-overflow-tooltip="false"
                      min-width="150"
                      label="费用科目三"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="cost" min-width="110" label="金额（元）" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.cost | applyAmount }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                prop="expenseNumber"
                label="单据编号"
                align="center"
                width="175"
              ></el-table-column>
              <el-table-column
                prop="applicantName"
                sortable
                label="申请人"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="deptName"
                sortable
                width="130"
                label="费用所属部门"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="collectionUnit"
                sortable
                min-width="280"
                :show-overflow-tooltip="false"
                label="收款单位"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="sumCost" width="120" sortable label="申请金额" align="center">
                <template slot-scope="scope">
                  {{ scope.row.sumcost | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="projectBudget"
                width="120"
                sortable
                label="申请时间"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.createdDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="statusName"
                :show-overflow-tooltip="false"
                width="150"
                label="流程进度"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div> -->
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 80,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    business: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paymentApplications: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      zzsList: [], //增值税类型
      dictData: {
        bidType: [], //投标类型
        businessType: [], //商务类型
        bidOpeningMethod: [], //开标方式
      },
      form: {
        changeFieldList: [], //变更字段
        newData: {
          business: {},
          paymentApplications: [],
        },
        oldData: {
          business: {},
          paymentApplications: [],
        }, //	旧数据
      },
      defaultBusiness: {
        businessName: '',
        ownerUnit: '',
        contacts: '',
        contactsPhone: '',
        createdDate: '',
        businessType: '',
        status: '',
        type: '',
      },
      oldChangeIds: [],
      newChangeIds: [],
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      CompanyMembers: state => state.business.CompanyMembers,
    }),
  },
  filters: {
    dateFormat(value) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd')
      } else {
        return ''
      }
    },
    timeFormat(value, fmt) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd hh:mm:ss')
      } else {
        return ''
      }
    },

    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  created() {
    this.getType()
  },
  mounted() {},
  methods: {
    getData() {
      this.loading = true
      this.$api.log
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
            if (Object.keys(this.form.oldData.business || {}).length === 0) {
              this.form.oldData.business = this.defaultBusiness
            }
            if (Object.keys(this.form.newData.business || {}).length === 0) {
              this.form.newData.business = this.defaultBusiness
            }
            this.oldChangeIds = []
            this.newChangeIds = []
            this.form.newData.paymentApplications = this.form.newData.paymentApplications || []
            this.form.oldData.paymentApplications = this.form.oldData.paymentApplications || []
            let newPayIds = this.form.newData.paymentApplications.map(item => item.id)
            let oldPayIds = this.form.oldData.paymentApplications.map(item => item.id)
            this.oldChangeIds = oldPayIds.filter(item => !newPayIds.includes(item))
            this.newChangeIds = newPayIds.filter(item => !oldPayIds.includes(item))

            if (!this.form.changeFieldList.includes('paymentApplications')) {
              this.form.oldData.paymentApplications = this.paymentApplications
              this.form.newData.paymentApplications = this.paymentApplications
            }

            if (!this.form.changeFieldList.includes('businessId')) {
              this.form.oldData.business = this.business
              this.form.newData.business = this.business
            }
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    getType() {
      this.$api.dict
        .listSysDictData('BID_TYPE', true)
        .then(res => {
          this.dictData.bidType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BID_OPENING_METHOD', true)
        .then(res => {
          this.dictData.bidOpeningMethod = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },

    leftCellClassName({ row, column, rowIndex, columnIndex }) {
      let index = this.oldChangeIds.findIndex(item => row.id == item)
      if (index !== -1) {
        return ['leftHeaderClassName']
      }
      return []
    },
    rightCellClassName({ row, column, rowIndex, columnIndex }) {
      let index = this.newChangeIds.findIndex(item => row.id == item)
      if (index !== -1) {
        return ['rightHeaderClassName']
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.business-center {
  display: flex;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
  .business-left {
    margin-right: 10px;
    width: 49%;
  }
  .business-right {
    margin-left: 10px;
    width: 49%;
  }

  .radio-group {
    display: flex;
    align-items: center;
    height: 45px;
  }
  .radio-label {
    width: 100px;
    font-size: 14px;
  }
}
.before-change {
  font-weight: 900;
  font-size: 18px;
  margin-right: 47%;
  color: blue;
}
.after-the-alteration {
  font-weight: 900;
  font-size: 18px;
  color: red;
  // #eef9b2
}

/deep/.leftHeaderClassName {
  background-color: #bff0de !important;
}
/deep/.rightHeaderClassName {
  background-color: #eef9b2 !important;
}
</style>
